@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=REM:wght@700&display=swap');

.navbar {
    background-color: #121212;
    height: 55px; /* Skinny navbar */
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0 20px; /* Add padding to the sides */
}

.navbar-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.navbar-logo {
    color: #7715F2; /* Accent color */
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'REM', sans-serif; /* Apply the Google Font */
}

.navbar-logo .white-o {
    color: #848484; /* White color for the "O"s */
}

.navbar-left-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: 40px; /* Space between the logo and the Flights link */
}

.navbar-left-menu li {
    display: inline;
    margin-right: 20px; /* Space between menu items */
}

.navbar-left-menu li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
    font-family: 'Roboto', sans-serif; /* Apply the Google Font */
}

.navbar-left-menu li a:hover {
    color: #7715F2; /* Accent color on hover */
}

.navbar-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    margin-left: auto; /* Pushes the menu to the far right */
    padding-right: 40px; /* Optional: add extra padding on the right side */
}

.navbar-menu li {
    display: inline;
}

.navbar-menu li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
    font-family: 'Roboto', sans-serif; /* Apply the Google Font */
}

.navbar-menu li a:hover {
    color: #7715F2; /* Accent color on hover */
}
