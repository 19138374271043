/* src/components/map/VatsimMap.css */

.toggle-buttons-container {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  

.leaflet-control-attribution {
    display: none !important;
}

/* Set the entire webpage background to black */
body {
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Ensure the map container background is black */
.leaflet-container {
  background-color: black !important;
}

/* Ensure individual map tiles have a black background */
.leaflet-tile-container img.leaflet-tile {
  background-color: black !important;
}
