.flights-container {
    padding: 20px;
    padding-top: 75px; /* Space from the navbar */
    background-color: #121212;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.table-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.flights-table-container {
    flex-grow: 1;
    overflow-y: auto; /* Enables vertical scrolling */
    padding-bottom: 50px; /* Add padding to ensure the last row is visible */
    margin-bottom: 10px; /* Ensure the last row is not cut off */
}

.flights-table {
    width: 100%;
    border-collapse: collapse;
}

.flights-table th {
    background-color: #333;
    cursor: pointer;
    text-align: center; /* Center all headings of columns */
    position: relative; /* Needed for positioning the caret */
    padding-right: 20px; /* Space for the caret */
}

.flights-table td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left; /* Left-align all data by default */
    vertical-align: middle; /* Center vertically */
}

.flights-table td.centered-data {
    text-align: center; /* Center text for altitude and speed data */
    font-size: 1.0em;
    vertical-align: middle; /* Ensures vertical centering */
}

.flights-table tbody tr:hover {
    background-color: #444;
}

.airport-info {
    display: flex;
    align-items: center;
    color: white;
}

.icao-box {
    background-color: #222;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
    flex-shrink: 0;
    width: 50px; /* Fixed width for ICAO box */
    text-align: center;
}

.icao {
    font-size: 0.8em;
}

.airport-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
}

.airport-info {
    gap: 10px;
}

/* Custom sort caret */
.flights-table th .sort-caret {
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-size: cover;
}

.flights-table th .sort-caret.asc {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"%3E%3Cpath d="M0 4h8l-4-4z" fill="%23fff"/%3E%3C/svg%3E');
}

.flights-table th .sort-caret.desc {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"%3E%3Cpath d="M0 0h8l-4 4z" fill="%23fff"/%3E%3C/svg%3E');
}
