.aircraft-popup {
    font-size: 12px;
    color: #333;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    max-width: 200px;
  }
  
  .aircraft-popup p {
    margin: 2px 0;
  }
  